import React, { useContext, useState } from "react";
import { WidgetDropdown, ProfileDropdown } from "../components/header";
import { Button, Section, Box } from "../components/elements";
import { DrawerContext } from "../context/Drawer";
import { ThemeContext } from "../context/Themes";
import { Logo } from "../components";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { useEffect } from "react";
import data from "../data/master/header.json";
import { setUserData, setToken, setRefreshToken, setLoginStatus } from "../actions";
import { useDispatch } from "react-redux";

export default function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { userData, token, loginStatus } = useSelector((state) => state.counter);
  const { drawer, toggleDrawer } = useContext(DrawerContext);
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [scroll, setScroll] = useState("fixed");

  const [count, setCount] = useState("0");

  window.addEventListener("scroll", () => {
    if (window.pageYOffset > 0) setScroll("sticky");
    else setScroll("fixed");
  });

  useEffect(() => {
    if (!loginStatus) {
      setUserData([]);
      dispatch(setLoginStatus(false));
      dispatch(setToken(""));
      dispatch(setRefreshToken(""));

      navigate("/");
    }
  }, [loginStatus]);

  useEffect(() => {
    fetchData();
  }, [location.pathname]);

  async function fetchData() {
    const options = {
      headers: { authorization: token },
    };
    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/dasborad/pendingSubdomain", options);
    if (Response.data.status == "success") {
      setCount(Response.data.result.pendingSubdomainCount);
    }
  }

  return (
    <Section as="header" className={`mc-header ${scroll}`}>
      <Logo src={data?.logo.src} alt={data?.logo.alt} name={"   "} href={"/crm"} />
      <Box className="mc-header-group">
        <Box className="mc-header-left">
          <Button icon={drawer ? "menu_open" : "menu"} className="mc-header-icon toggle" onClick={toggleDrawer} />
        </Box>
        <Box className="mc-header-right">
          <Button icon={theme} title={data.theme.title} onClick={toggleTheme} className={`mc-header-icon ${data.theme.addClass}`} />
          <Button icon={data.language.icon} title={data.theme.title} onClick={() => navigate("/subdomains")} className={`mc-header-icon ${data.theme.addClass}`} badge={{ text: count, variant: "primary" }} />
          <ProfileDropdown name={userData.fname + " " + userData.lname} image={data.profile.image} username={userData.fname + " " + userData.lname} dropdown={data.profile.dropdown} />
        </Box>
      </Box>
    </Section>
  );
}
