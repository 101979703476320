import React from "react";
import { Box, Text, Heading } from "../elements";

export default function AnalyticsCard({ digit, label, variant, dataSet, dataKey, dotsMenu }) {
  return (
    <Box className={`mc-analytics-card ${variant.name}`}>
      <Box className="mc-analytics-card-group">
        <Box className="mc-analytics-card-content">
          <Heading>{digit}</Heading>
          <Text>{label}</Text>
        </Box>
      </Box>
    </Box>
  );
}
