import React from "react";
import data from "../../data/master/analytics.json";
import PageLayout from "../../layouts/PageLayout";
import { Row, Col } from "react-bootstrap";
import { Box, Anchor } from "../../components/elements";
import { PagesTable } from "../../components/tables";
import { CardHeader, AnalyticsCard } from "../../components/cards";
import { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function Analytics() {
  const { userData, token, refreshToken } = useSelector((state) => state.counter);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [data2, setData2] = useState({});
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerpage] = useState(100);
  const [totalRecod, setTotalRecod] = useState(0);
  const [pageViewData, setPageViewData] = useState([]);

  useEffect(() => {
    fetchData(1);
  }, []);

  async function fetchData(pageNumber) {
    const options = {
      headers: { authorization: token },
    };

    const params = {
      page: perPage * (pageNumber - 1),
      limit: perPage,
      startDate: startDate,
      endDate: endDate,
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/domain-visit/list?" + queryString, options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setTotalRecod(Response.data.totalCount);
        setData2(Response.data);

        var pageViewData = [];
        for (let i = 0; i < Response.data.result.length; i++) {
          pageViewData.push({
            path: Response.data.result[i].url,
            visitor: Response.data.result[i].total,
            bounce: Response.data.result[i].unique,
          });
        }
        setPageViewData(pageViewData);
      }
    }
  }

  // On Pagination Change
  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    fetchData(pageNumber);
  }

  return (
    <PageLayout>
      <Row>
        <Col xl={3}>{data2.stats && <AnalyticsCard digit={data2.stats.thisWeekCount} label={"This week"} dataSet={[]} dataKey={""} variant={{ name: "blue", color: "#89ecb3" }} dotsMenu={[]} />}</Col>
        <Col xl={3}>{data2.stats && <AnalyticsCard digit={data2.stats.last15DaysCount} label={"15 day"} dataSet={[]} dataKey={""} variant={{ name: "blue", color: "#89ecb3" }} dotsMenu={[]} />}</Col>
        <Col xl={3}>{data2.stats && <AnalyticsCard digit={data2.stats.thisMonthCount} label={"this month"} dataSet={[]} dataKey={""} variant={{ name: "blue", color: "#89ecb3" }} dotsMenu={[]} />}</Col>
        <Col xl={3}>{data2.stats && <AnalyticsCard digit={data2.stats.previousMonthCount} label={"previous month"} dataSet={[]} dataKey={""} variant={{ name: "blue", color: "#89ecb3" }} dotsMenu={[]} />}</Col>

        <Col xl={12}>
          <Box className="mc-card">
            <CardHeader title={data?.pages.title} dotsMenu={data?.dotsMenu} />

            <Row>
              <Col md={4}>
                <div class="form-group">
                  <label>Start Date</label>
                  <DatePicker isClearable className="form-control" onChange={(date) => setStartDate(date)} selected={startDate} />
                </div>
              </Col>

              <Col md={4}>
                <div class="form-group">
                  <label>End Date</label>
                  <DatePicker isClearable className="form-control" onChange={(date) => setEndDate(date)} selected={endDate} />
                </div>
              </Col>

              <Col md={4}>
                <div class="form-group">
                  <Anchor
                    icon={"search"}
                    text={"Search"}
                    href={"#"}
                    className="mc-btn primary mt-4 w-100"
                    onClick={() => {
                      fetchData(1);
                      setActivePage(1);
                    }}
                  />
                </div>
              </Col>
            </Row>
            <br />
            <PagesTable thead={["page name", "total visitors", "unique visitors"]} tbody={pageViewData} totalRecod={totalRecod} />

            <Box className="mc-paginate">
              <nav aria-label="Page navigation example">
                {totalRecod > perPage ? (
                  <Pagination
                    prevPageText="prev"
                    nextPageText="next"
                    firstPageText="first"
                    lastPageText="last"
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalRecod}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination justify-content-center"
                  />
                ) : (
                  ""
                )}
              </nav>
            </Box>
          </Box>
        </Col>
      </Row>
    </PageLayout>
  );
}
