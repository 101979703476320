import axios from "axios";

async function setLog(payload) {
  await axios.post(process.env.REACT_APP_API_URL + "app/log/add", {
    adminUser: payload.adminUser,
    module: payload.module,
    action: payload.action,
    note: payload.note,
  });
  return;
}

export default setLog;
