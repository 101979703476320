import axios from "axios";

export const incrementCounter = () => {
  return {
    type: "INCREMENT_COUNTER",
  };
};

export const decrementCounter = () => {
  return {
    type: "DECREMENT_COUNTER",
  };
};

export const setUserData = (payload) => (dispatch) => {
  return dispatch({
    type: "SET_USER_DATA",
    payload: payload,
  });
};

export const setLoginStatus = (payload) => (dispatch) => {
  return dispatch({
    type: "SET_LOGIN_STATUS",
    payload: payload,
  });
};

export const setToken = (payload) => (dispatch) => {
  return dispatch({
    type: "SET_TOKEN",
    payload: payload,
  });
};

export const setRefreshToken = (payload) => (dispatch) => {
  return dispatch({
    type: "SET_REFRESH_TOKEN",
    payload: payload,
  });
};

export const setLog = () => async (payload) => {
  alert();
  await axios.post(process.env.REACT_APP_API_URL + "app/log/add", {
    adminUser: payload.adminUser,
    module: payload.module,
    action: payload.action,
    note: payload.note,
  });
  return;
};
