import React from "react";
import { useState, useEffect } from "react";
import { Box, Form, Heading, Button, Anchor, Image, Text } from "../../components/elements";
import IconField from "../../components/fields/IconField";
import Logo from "../../components/Logo";
import data from "../../data/master/forgot.json";
import axios from "axios";
import qs from "qs";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
export default function ForgotPassword() {
  const navigate = useNavigate();
  const [Saving, setSaving] = useState(false);
  const [email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [OTP, setOTP] = useState("");

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    setEmail(urlParams.get("email"));
  }, []);

  async function handleSave() {
    if (!email && email == "") {
      toast.error("Enter email address");
      return;
    }

    if (!Password && Password == "") {
      toast.error("Enter new password");
      return;
    }

    if (!OTP && OTP == "") {
      toast.error("Enter OTP");
      return;
    }

    setSaving(true);

    const Response = await axios.post(
      process.env.REACT_APP_API_URL + "app/user/update-password",
      qs.stringify({
        email: email,
        otp: OTP,
        password: Password,
      })
    );

    if (Response.data.status == "success") {
      setSaving(false);
      toast.success(Response.data.message);
      navigate("/");
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  return (
    <Box className="mc-auth">
      <Image className="mc-auth-pattern" src={data?.pattern.src} alt={data?.pattern.alt} />
      <Box className="mc-auth-group">
        <Logo src={data?.logo.src} alt={data?.logo.alt} href={data?.logo.path} className="mc-auth-logo" />
        <Heading as="h4" className="mc-auth-title text-white">
          Set new password
        </Heading>
        <Form className="mc-auth-form">
          <div class="mc-icon-field w-100 h-sm">
            <i class="material-icons">email</i>
            <input type="email" value={email} placeholder="Enter your email" readOnly={true} />
          </div>
          <IconField icon={"lock"} type={"password"} classes={"w-100 h-sm"} placeholder={"Enter new password"} passwordVisible={true} onChange={(e) => setPassword(e.target.value)} />
          <IconField icon={"lock"} type={"password"} classes={"w-100 h-sm"} placeholder={"Enter OTP"} passwordVisible={true} onChange={(e) => setOTP(e.target.value)} />
          <button className="btn btn-primary w-100" type="button" disable={!Saving} onClick={() => handleSave()}>
            {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Proceed</>}
          </button>
        </Form>
        <Box className="mc-auth-navigate">
          <Text as="span">{data?.navigate.title}</Text>
          <Anchor href={data?.navigate.path}>{data?.navigate.text}</Anchor>
        </Box>
      </Box>
    </Box>
  );
}
