import React from "react";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { CardLayout } from "./../../components/cards";
import { Table, Thead, Tbody, Th, Tr, Td } from "./../../components/elements/Table";
import { Anchor, Box, Text, Button, Icon } from "./../../components/elements";
import { Breadcrumb } from "./../../components";
import PageLayout from "../../layouts/PageLayout";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import Modal from "react-bootstrap/Modal";
import Analytics from "./subdomainAnalytics";
import setLog from "../../lib";

export default function ProductList() {
  const navigate = useNavigate();
  const { userData, token, refreshToken } = useSelector((state) => state.counter);
  const [showSwal, setShowSwal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerpage] = useState(20);
  const [totalRecod, setTotalRecod] = useState(0);
  const [data, setData] = useState({});
  const [id, setId] = useState("");
  const [keyword, seKeyword] = useState("");
  const [retreatURL, seRetreatURL] = useState("");
  const [marketingCollateral, setMarketingCollateral] = useState("");
  const [status, setStatus] = useState("");
  const [title, setTitle] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    fetchData(1);
  }, []);

  async function fetchData(pageNumber) {
    const options = {
      headers: { authorization: token },
    };

    const params = {
      page: perPage * (pageNumber - 1),
      limit: perPage,
      retreatURL: retreatURL,
      keyword: keyword,
      marketingCollateral: marketingCollateral,
      status: status,
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/sub-domain/list?" + queryString, options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setTotalRecod(Response.data.totalCount);
        setData(Response.data.result);
      }
    } else {
      toast.error(Response.data.message);
    }
  }

  // Soft Delete Record
  async function handleDelete() {
    const options = {
      headers: { authorization: token },
    };

    try {
      axios.post(process.env.REACT_APP_API_URL + "app/sub-domain/delete/" + id, {}, options).then((response) => {
        if (response.data.status == "success") {
          toast(response.data.message);
          fetchData(1);
          setActivePage(1);
          setShowSwal(false);

          setLog({
            adminUser: userData._id,
            module: "Subdomain",
            action: "Delete",
            note: title,
          });
          setTitle("");
        } else {
          toast.error("Something went wrong");
        }
      });
    } catch (err) {
      toast.error(err.message);
    }
  }

  // On Pagination Change
  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    fetchData(pageNumber);
  }

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={"Subdomains"}></Breadcrumb>
          </CardLayout>
        </Col>

        <Col xl={12}>
          <CardLayout>
            <Row>
              <Col md={4}>
                <div class="form-group">
                  <label>Keyword</label>
                  <input type="text" placeholder="Title" className="form-control" onChange={(e) => seKeyword(e.target.value)} value={keyword} />
                </div>
              </Col>

              <Col md={4}>
                <div class="form-group">
                  <label>Retreat URL</label>
                  <input type="text" placeholder="Title" className="form-control" onChange={(e) => seRetreatURL(e.target.value)} value={retreatURL} />
                </div>
              </Col>

              <Col md={4}>
                <div class="form-group">
                  <label>Host Type</label>
                  <select class="form-control" onChange={(e) => setMarketingCollateral(e.target.value)} value={marketingCollateral}>
                    <option value=""> Select </option>
                    <option value="individual"> Solo Host </option>
                    <option value="cohost"> Co-Hosts </option>
                  </select>
                </div>
              </Col>

              <Col md={4}>
                <div class="form-group">
                  <label>Status</label>
                  <select class="form-control" onChange={(e) => setStatus(e.target.value)} value={status}>
                    <option value=""> Select </option>
                    <option value="0"> Pending </option>
                    <option value="1"> Live </option>
                    <option value="2"> Inactive </option>
                  </select>
                </div>
              </Col>

              <Col md={4}>
                <div class="form-group">
                  <Anchor
                    icon={"search"}
                    text={"Search"}
                    href={"#"}
                    className="mc-btn primary mt-4 w-100"
                    onClick={() => {
                      setActivePage(1);
                      fetchData(1);
                    }}
                  />
                </div>
              </Col>

              <Col xl={12}>
                <Box className="mc-table-responsive">
                  <Table className="mc-table">
                    <Thead className="mc-table-head primary">
                      <Tr>
                        <Th>Type</Th>
                        <Th>Company/Name</Th>
                        <Th>Retreat URL</Th>
                        <Th>Views</Th>
                        <Th>Status</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody className="mc-table-body even">
                      {data?.length > 0 &&
                        data.map((item, key) => (
                          <>
                            <Tr key={key}>
                              <Td>{item.marketingCollateral}</Td>
                              <Td>{item.name}</Td>
                              <Td>{item.retreatURL}.iamfit4travel.com</Td>
                              <Td>{item.visitCounter}</Td>
                              <Td>
                                {item.status == 0 && <div className="text-warning">Pending</div>}
                                {item.status == 1 && <div className="text-success">Live</div>}
                                {item.status == 2 && <div className="text-primary">Inactive</div>}
                              </Td>
                              <Td>
                                <Box className="mc-table-action">
                                  <Button
                                    title="Delete"
                                    className="material-icons bg-primary"
                                    onClick={() => {
                                      setId(item._id);
                                      handleShow();
                                    }}
                                  >
                                    <Icon>analytics</Icon>
                                  </Button>

                                  <a class="material-icons view" target="_blank" title="View site" href={"https://" + item.retreatURL + ".iamfit4travel.com"}>
                                    <i class="material-icons">link</i>
                                  </a>
                                  {userData.role && userData.role.access.subDomains.edit && (
                                    <Anchor href={"/manage-subdomains?id=" + item._id} title="Edit" className="material-icons edit">
                                      <Icon>edit</Icon>
                                    </Anchor>
                                  )}
                                  {userData.role && userData.role.access.subDomains.delete && (
                                    <Button
                                      title="Delete"
                                      className="material-icons delete"
                                      onClick={() => {
                                        setId(item._id);
                                        setShowSwal(true);
                                        setTitle(item.name);
                                      }}
                                    >
                                      <Icon>delete</Icon>
                                    </Button>
                                  )}
                                </Box>
                              </Td>
                            </Tr>
                          </>
                        ))}
                    </Tbody>
                  </Table>
                </Box>{" "}
                <Box className="mc-paginate">
                  <Text className="mc-paginate-title">
                    Total <b>{totalRecod}</b> Results Found
                  </Text>
                  <nav aria-label="Page navigation example">
                    {totalRecod > perPage ? (
                      <Pagination
                        prevPageText="prev"
                        nextPageText="next"
                        firstPageText="first"
                        lastPageText="last"
                        activePage={activePage}
                        itemsCountPerPage={perPage}
                        totalItemsCount={totalRecod}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                        innerClass="pagination justify-content-center"
                      />
                    ) : (
                      ""
                    )}
                  </nav>
                </Box>
              </Col>
            </Row>
          </CardLayout>
        </Col>
      </Row>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Analytics</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Analytics id={id} />
        </Modal.Body>
      </Modal>

      {showSwal && (
        <SweetAlert warning showCancel confirmBtnText="Yes, delete it!" confirmBtnBsStyle="danger" cancelBtnBsStyle="primary" title="Are you sure?" onConfirm={handleDelete} onCancel={() => setShowSwal(false)}>
          This listing will be permanently deleted from your account.
        </SweetAlert>
      )}
    </PageLayout>
  );
}
