import React from "react";
import PageLayout from "../../layouts/PageLayout";
import { Row, Col } from "react-bootstrap";
import { Box, Anchor } from "../../components/elements";
import { PagesTable } from "../../components/tables";
import { Table, Thead, Tbody, Th, Tr, Td } from "./../../components/elements/Table";
import { CardHeader, AnalyticsCard } from "../../components/cards";
import { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Text } from "./../../components/elements";

export default function Analytics() {
  const { userData, token, refreshToken } = useSelector((state) => state.counter);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [data2, setData2] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerpage] = useState(100);
  const [totalRecod, setTotalRecod] = useState(0);
  const [pageViewData, setPageViewData] = useState([]);
  const [data, setData] = useState([]);
  const [user, setUser] = useState("");

  useEffect(() => {
    fetchData(1);
    fetchData2(1);
  }, []);

  async function fetchData2(pageNumber) {
    const options = {
      headers: { authorization: token },
    };

    const params = {
      page: perPage * (pageNumber - 1),
      limit: perPage,
    };

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/user/all", options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setTotalRecod(Response.data.totalCount);
        setData(Response.data.result);
      }
    }
  }

  async function fetchData(pageNumber) {
    const options = {
      headers: { authorization: token },
    };

    const params = {
      page: perPage * (pageNumber - 1),
      limit: perPage,
      startDate: startDate,
      endDate: endDate,
      user: user,
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/log/list?" + queryString, options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setTotalRecod(Response.data.totalCount);
        setData2(Response.data);

        var pageViewData = [];
        for (let i = 0; i < Response.data.result.length; i++) {
          pageViewData.push({
            User: Response.data.result[i].adminUser.fname + " " + Response.data.result[i].adminUser.lname,
            Module: Response.data.result[i].module,
            Action: Response.data.result[i].action,
            Note: Response.data.result[i].note,
            createdAt: Response.data.result[i].createdAt,
          });
        }
        setPageViewData(pageViewData);
      }
    }
  }

  // On Pagination Change
  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    fetchData(pageNumber);
  }

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <Box className="mc-card">
            <CardHeader title={"Logs"} />

            <Row>
              <Col md={3}>
                <div class="form-group">
                  <label>Users</label>
                  <select className="form-control" onChange={(e) => setUser(e.target.value)} value={user}>
                    <option value=""> Select </option>
                    {data?.length > 0 &&
                      data.map((item, key) => (
                        <option value={item._id} key={key}>
                          {item.fname} {item.lname}
                        </option>
                      ))}
                  </select>
                </div>
              </Col>

              <Col md={3}>
                <div class="form-group">
                  <label>Start Date</label>
                  <DatePicker isClearable className="form-control" onChange={(date) => setStartDate(date)} selected={startDate} />
                </div>
              </Col>

              <Col md={3}>
                <div class="form-group">
                  <label>End Date</label>
                  <DatePicker isClearable className="form-control" onChange={(date) => setEndDate(date)} selected={endDate} />
                </div>
              </Col>

              <Col md={3}>
                <div class="form-group">
                  <Anchor
                    icon={"search"}
                    text={"Search"}
                    href={"#"}
                    className="mc-btn primary mt-4 w-100"
                    onClick={() => {
                      fetchData(1);
                      setActivePage(1);
                    }}
                  />
                </div>
              </Col>
            </Row>
            <br />

            <Box className="mc-table-responsive thin-scrolling">
              <Table className="table table-sm">
                <Thead className="mc-table-head primary">
                  <Tr>
                    <Th>Date</Th>
                    <Th>User</Th>
                    <Th>Module</Th>
                    <Th>Action</Th>
                    <Th>Note</Th>
                  </Tr>
                </Thead>
                <tbody style={{ fontSize: 12 }}>
                  {pageViewData &&
                    pageViewData.map((item, index) => (
                      <Tr key={index}>
                        <Td>{moment(item.createdAt).format("MMMM Do YYYY, h:mm a")}</Td>
                        <Td>{item.User}</Td>
                        <Td>{item.Module}</Td>
                        <Td>{item.Action}</Td>
                        <Td>{item.Note}</Td>
                      </Tr>
                    ))}
                </tbody>
              </Table>
              <br />
              <Text className="mc-paginate-title">
                Total <b>{totalRecod}</b> Results Found
              </Text>
            </Box>

            <Box className="mc-paginate">
              <nav aria-label="Page navigation example">
                {totalRecod > perPage ? (
                  <Pagination
                    prevPageText="prev"
                    nextPageText="next"
                    firstPageText="first"
                    lastPageText="last"
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalRecod}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination justify-content-center"
                  />
                ) : (
                  ""
                )}
              </nav>
            </Box>
          </Box>
        </Col>
      </Row>
    </PageLayout>
  );
}
