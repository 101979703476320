import React from "react";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Table, Thead, Tbody, Th, Tr, Td } from "./../../components/elements/Table";
import { Anchor, Box, Text } from "./../../components/elements";

import axios from "axios";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
export default function ProductList({ userId }) {
  const navigate = useNavigate();
  const { userData, token, refreshToken } = useSelector((state) => state.counter);

  const [keyword, seKeyword] = useState("");
  const [retreatURL, seRetreatURL] = useState("");
  const [marketingCollateral, setMarketingCollateral] = useState("");

  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerpage] = useState(20);
  const [totalRecod, setTotalRecod] = useState(0);
  const [data, setData] = useState({});
  const [retreatsproducts, setRetreatsproducts] = useState([]);

  useEffect(() => {
    fetchData(1);
  }, []);

  async function fetchData(pageNumber) {
    const options = {
      headers: { authorization: token },
    };

    const params = {
      page: perPage * (pageNumber - 1),
      limit: perPage,
      retreatURL: retreatURL,
      keyword: keyword,
      marketingCollateral: marketingCollateral,
      userId: userId,
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/sub-domain/list?" + queryString, options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setTotalRecod(Response.data.totalCount);
        setData(Response.data.result);
        setRetreatsproducts(Response.data.retreatsproducts);
      }
    } else {
      toast.error(Response.data.message);
    }
  }

  // On Pagination Change
  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    fetchData(pageNumber);
  }

  async function userDomain(productId) {
    const options = {
      headers: { authorization: token },
    };

    const params = {
      subdomain: productId,
      userId: userId,
    };
    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");
    await axios.get(process.env.REACT_APP_API_URL + "app/sub-domain/assign-domain?" + queryString, options);
  }

  function isChecked(id) {
    var data = retreatsproducts.find(function (ele) {
      return ele.subdomain == id;
    });
    if (data) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      <Row>
        <Col md={3}>
          <div class="form-group">
            <label>Keyword</label>
            <input type="text" placeholder="Title" className="form-control" onChange={(e) => seKeyword(e.target.value)} value={keyword} />
          </div>
        </Col>

        <Col md={3}>
          <div class="form-group">
            <label>Retreat URL</label>
            <input type="text" placeholder="Title" className="form-control" onChange={(e) => seRetreatURL(e.target.value)} value={retreatURL} />
          </div>
        </Col>

        <Col md={3}>
          <div class="form-group">
            <label>Marketing Collateral</label>
            <select class="form-control" onChange={(e) => setMarketingCollateral(e.target.value)} value={marketingCollateral}>
              <option value=""> Select </option>
              <option value="individual"> Individual </option>
              <option value="cohost"> Co-hosts </option>
            </select>
          </div>
        </Col>

        <Col md={3}>
          <div class="form-group">
            <Anchor
              icon={"search"}
              text={"Search"}
              href={"#"}
              className="mc-btn primary mt-4 w-100"
              onClick={() => {
                setActivePage(1);
                fetchData(1);
              }}
            />
          </div>
        </Col>

        <Col xl={12}>
          <Box className="mc-table-responsive">
            <Table className="mc-table">
              <Thead className="mc-table-head primary">
                <Tr>
                  <Th>Type</Th>
                  <Th>Company/Name</Th>
                  <Th>Retreat URL</Th>
                  <Th>Assign</Th>
                </Tr>
              </Thead>
              <Tbody className="mc-table-body even">
                {data?.length > 0 &&
                  data.map((item, key) => (
                    <>
                      <Tr key={key}>
                        <Td>{item.marketingCollateral}</Td>
                        <Td>{item.name}</Td>
                        <Td>{item.retreatURL}.iamfit4travel.com</Td>

                        <Td>
                          <input class="form-check-input" type="checkbox" defaultChecked={isChecked(item._id)} onChange={() => userDomain(item._id)} />
                        </Td>
                      </Tr>
                    </>
                  ))}
              </Tbody>
            </Table>
          </Box>
          <Box className="mc-paginate">
            <Text className="mc-paginate-title">
              Total <b>{totalRecod}</b> Results Found
            </Text>
            <nav aria-label="Page navigation example">
              {totalRecod > perPage ? (
                <Pagination
                  prevPageText="prev"
                  nextPageText="next"
                  firstPageText="first"
                  lastPageText="last"
                  activePage={activePage}
                  itemsCountPerPage={perPage}
                  totalItemsCount={totalRecod}
                  onChange={handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                  innerClass="pagination justify-content-center"
                />
              ) : (
                ""
              )}
            </nav>
          </Box>
        </Col>
      </Row>
    </>
  );
}
