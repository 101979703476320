import React, { useState, useEffect, useRef } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Row, Col } from "react-bootstrap";
import { Table, Thead, Tbody, Th, Tr, Td } from "./../../components/elements/Table";
import { Anchor, Box, Text, Button, Icon } from "./../../components/elements";
import Pagination from "react-js-pagination";
import qs from "qs";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import AssignProduct from "./assignProduct";
import { FiThumbsUp, FiThumbsDown } from "react-icons/fi";
import setLog from "../../lib";

function NavBar({ subdomainId, name }) {
  const { userData, token, refreshToken } = useSelector((state) => state.counter);
  const [showSwal, setShowSwal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerpage] = useState(20);
  const [totalRecod, setTotalRecod] = useState(0);
  const [data, setData] = useState({});
  const [masterDestinations, setMasterDestination] = useState("");
  const [subMasterDestinations, setSubMasterDestination] = useState("");
  const [destination, setDestination] = useState("");
  const [subDestination, setSubDestination] = useState("");
  const [id, setId] = useState("");
  const [masterExpereances, setMasterExpereance] = useState("");
  const [subMasterExpereances, setSubMasterExpereance] = useState("");
  const [expereance, setExpereance] = useState("");
  const [subExpereance, setSubExpereance] = useState("");
  const [keyword, seKeyword] = useState("");
  const [title, setTitle] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    fetchDestination();
    fetchExpereance();
  }, []);

  useEffect(() => {
    if (!show && subdomainId && subdomainId != "") {
      fetchData(1);
      setActivePage(1);
    }
  }, [show]);

  async function fetchExpereance() {
    const options = {
      headers: { authorization: token },
    };

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/experiences/list", options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setMasterExpereance(Response.data.result);
      }
    } else {
      toast.error(Response.data.message);
    }
  }

  async function fetchDestination() {
    const options = {
      headers: { authorization: token },
    };

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/destination/list", options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setMasterDestination(Response.data.result);
      }
    } else {
      toast.error(Response.data.message);
    }
  }

  function getSubDestinations(id) {
    setDestination(id);
    if (masterDestinations) var data = masterDestinations.find((e) => e._id == id);
    if (data && data.childs) setSubMasterDestination(data.childs);
  }

  function getSubExpereance(id) {
    setExpereance(id);
    if (masterExpereances) var data = masterExpereances.find((e) => e._id == id);
    if (data && data.childs) setSubMasterExpereance(data.childs);
  }

  useEffect(() => {
    if (subdomainId && subdomainId != "") {
      fetchData(1);
    }
  }, [subdomainId]);

  async function fetchData(pageNumber) {
    const options = {
      headers: { authorization: token },
    };

    const params = {
      page: perPage * (pageNumber - 1),
      limit: perPage,
      destination: destination,
      subDestination: subDestination,
      expereance: expereance,
      subExpereance: subExpereance,
      keyword: keyword,
      subdomain: subdomainId,
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/subdomain-products/list?" + queryString, options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setTotalRecod(Response.data.totalCount);
        setData(Response.data.result);
      }
    } else {
      toast.error(Response.data.message);
    }
  }

  async function deleteProduct() {
    const options = {
      headers: { authorization: token },
    };

    const params = {
      productId: id,
      subdomainId: subdomainId,
    };
    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");
    await axios.get(process.env.REACT_APP_API_URL + "app/sub-domain/assign-product?" + queryString, options);
    setShowSwal(false);
    fetchData(1);
    setActivePage(1);

    setLog({
      adminUser: userData._id,
      module: "Subdomain Product: " + name,
      action: "Delete",
      note: title,
    });
  }

  // On Pagination Change
  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    fetchData(pageNumber);
  }
  function UpdateStatus(id, displayHome) {
    let result = window.confirm("Are you sure?");
    if (result) {
      const options = {
        headers: { authorization: token },
      };

      axios.post(process.env.REACT_APP_API_URL + "app/subdomain-products/update/" + id, qs.stringify({ displayHome: displayHome }), options).then((response) => {
        if (response.data.status == "success") {
          toast(response.data.message);
          handlePageChange(1);
          var action = displayHome == 1 ? "Display home status: Yes" : "Display home status: No";
          setLog({
            adminUser: userData._id,
            module: "Subdomain Product: " + name,
            action: action,
            note: name,
          });
        } else {
          toast(response.data.message);
        }
      });
    }
  }
  return (
    <>
      <Row>
        {" "}
        <Col md={4}>
          <div class="form-group">
            <label>Keyword</label>
            <input type="text" placeholder="Title" className="form-control" onChange={(e) => seKeyword(e.target.value)} value={keyword} />
          </div>
        </Col>
        <Col md={4}>
          <div class="form-group">
            <label>Destination</label>
            <select class="form-control" onChange={(e) => getSubDestinations(e.target.value)} value={destination}>
              <option value=""> Select </option>
              {masterDestinations?.length > 0 && masterDestinations.map((item, key) => <option value={item._id}>{item.title}</option>)}
            </select>
          </div>
        </Col>
        <Col md={4}>
          <div class="form-group">
            <label>Sub Destination</label>
            <select class="form-control" onChange={(e) => setSubDestination(e.target.value)} value={subDestination}>
              <option value=""> Select </option>
              {subMasterDestinations?.length > 0 && subMasterDestinations.map((item, key) => <option value={item._id}>{item.title}</option>)}
            </select>
          </div>
        </Col>
        <Col md={4}>
          <div class="form-group">
            <label>Experience</label>
            <select class="form-control" onChange={(e) => getSubExpereance(e.target.value)} value={expereance}>
              <option value=""> Select </option>
              {masterExpereances?.length > 0 && masterExpereances.map((item, key) => <option value={item._id}>{item.title}</option>)}
            </select>
          </div>
        </Col>
        <Col md={4}>
          <div class="form-group">
            <label>Sub Experience</label>
            <select class="form-control" onChange={(e) => setSubExpereance(e.target.value)} value={subExpereance}>
              <option value=""> Select </option>
              {subMasterExpereances?.length > 0 && subMasterExpereances.map((item, key) => <option value={item._id}>{item.title}</option>)}
            </select>
          </div>
        </Col>
        <Col md={2}>
          <div class="form-group">
            <Button
              icon={"search"}
              text={"Search"}
              className="mc-btn primary mt-4 w-100"
              onClick={() => {
                setActivePage(1);
                fetchData(1);
              }}
            />
          </div>
        </Col>
        <Col md={2}>
          <Button icon={"add"} text={"Assign"} className="mc-btn primary mt-4 w-100" onClick={() => handleShow()}></Button>
        </Col>
      </Row>
      <br />
      <Col xl={12}>
        <Box className="mc-table-responsive">
          <Table className="mc-table">
            <Thead className="mc-table-head primary">
              <Tr>
                <Th>Title</Th>
                <Th>Location</Th>
                <Th>Display Home</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody className="mc-table-body even">
              {data?.length > 0 &&
                data.map((item, key) => (
                  <>
                    <Tr key={key}>
                      <Td>
                        <strong>{item.title}</strong>
                      </Td>
                      <Td>
                        <strong>{item.parentDestination && item.parentDestination.title}</strong> - {item.subDestination && item.subDestination.title}
                      </Td>{" "}
                      <Td>
                        {item.displayHome == 0 ? (
                          <a href="#" onClick={() => UpdateStatus(item._id, 1)}>
                            <FiThumbsDown size={25} title="No" />
                          </a>
                        ) : (
                          <a href="#" onClick={() => UpdateStatus(item._id, 0)}>
                            <FiThumbsUp size={25} title=" Yes" />
                          </a>
                        )}
                      </Td>
                      <Td>
                        <Box className="mc-table-action">
                          <Anchor href={"/manage-subdomain-products?id=" + item._id + "&subdomain=" + subdomainId} title="Edit" className="material-icons edit">
                            <Icon>edit</Icon>
                          </Anchor>
                          <Button
                            title="Delete"
                            className="material-icons delete"
                            onClick={() => {
                              setId(item.products);
                              setShowSwal(true);
                              setTitle(item.title);
                            }}
                          >
                            <Icon>delete</Icon>
                          </Button>
                        </Box>
                      </Td>
                    </Tr>
                  </>
                ))}
            </Tbody>
          </Table>
        </Box>{" "}
        <Box className="mc-paginate">
          <Text className="mc-paginate-title">
            Total <b>{totalRecod}</b> Results Found
          </Text>
          <nav aria-label="Page navigation example">
            {totalRecod > perPage ? (
              <Pagination
                prevPageText="prev"
                nextPageText="next"
                firstPageText="first"
                lastPageText="last"
                activePage={activePage}
                itemsCountPerPage={perPage}
                totalItemsCount={totalRecod}
                onChange={handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
                innerClass="pagination justify-content-center"
              />
            ) : (
              ""
            )}
          </nav>
        </Box>
      </Col>{" "}
      {showSwal && (
        <SweetAlert warning showCancel confirmBtnText="Yes, delete it!" confirmBtnBsStyle="danger" cancelBtnBsStyle="primary" title="Are you sure?" onConfirm={deleteProduct} onCancel={() => setShowSwal(false)}>
          This listing will be permanently removed from the subdomain.
        </SweetAlert>
      )}
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Assign product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AssignProduct subdomainId={subdomainId} name={name} />
        </Modal.Body>
      </Modal>
    </>
  );
}
export default NavBar;
