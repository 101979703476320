const initialState = {
  counter: 0,
  userData: {},
  loginStatus: false,
  token: "",
  refreshToken: "",
};

const counterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "INCREMENT_COUNTER":
      return {
        ...state,
        counter: action.payload,
      };
    case "DECREMENT_COUNTER":
      return {
        ...state,
        counter: action.payload,
      };
    case "SET_USER_DATA":
      return {
        ...state,
        userData: action.payload,
      };
    case "SET_LOGIN_STATUS":
      return {
        ...state,
        loginStatus: action.payload,
      };

    case "SET_TOKEN":
      return {
        ...state,
        token: action.payload,
      };

    case "SET_REFRESH_TOKEN":
      return {
        ...state,
        refreshToken: action.payload,
      };
    default:
      return state;
  }
};

export default counterReducer;
