import React from "react";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { CardLayout } from "./../../components/cards";
import { Table, Thead, Tbody, Th, Tr, Td } from "./../../components/elements/Table";
import { Anchor, Box, Text, Button, Icon } from "./../../components/elements";
import { Breadcrumb } from "./../../components";
import PageLayout from "../../layouts/PageLayout";
import Offcanvas from "react-bootstrap/Offcanvas";
import { LabelField } from "./../../components/fields";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

export default function ProductList() {
  const { userData, token, refreshToken } = useSelector((state) => state.counter);
  const [showSwal, setShowSwal] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [Saving, setSaving] = useState(false);
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [mode, setMode] = useState("add");
  const [data, setData] = useState({});
  const [uploadData, setUploadData] = useState(false);
  const [id, setId] = useState("");
  const [link, setLink] = useState("");

  async function handleSave() {
    if (!title && title == "") {
      toast.error("Enter destination name");
      return;
    }

    setSaving(true);
    const options = {
      headers: { authorization: token },
      "Content-Type": "application/x-www-form-urlencoded",
    };

    const uploadFormData = new FormData();

    uploadFormData.append("title", title);
    uploadFormData.append("desc", desc);
    uploadFormData.append("upload", uploadData);
    uploadFormData.append("link", link);

    if (mode == "add") {
      const Response = await axios.post(process.env.REACT_APP_API_URL + "app/slider/add", uploadFormData, options);
      if (Response.data.status == "success") {
        setTitle("");
        setDesc("");
        setSaving(false);
        toast.success(Response.data.message);
        setShow(false);
        fetchData(1);
      } else {
        setSaving(false);
        toast.error(Response.data.message);
      }
    } else {
      const Response = await axios.post(process.env.REACT_APP_API_URL + "app/slider/update/" + id, uploadFormData, options);
      if (Response.data.status == "success") {
        setTitle("");
        setDesc("");
        setSaving(false);
        toast.success(Response.data.message);
        setShow(false);
        fetchData(1);
      } else {
        setSaving(false);
        toast.error(Response.data.message);
      }
    }
  }

  useEffect(() => {
    fetchData(1);
  }, []);

  async function fetchData(pageNumber) {
    const options = {
      headers: { authorization: token },
    };

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/slider/list", options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setData(Response.data.result);
      }
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  // Soft Delete Record
  async function handleDelete() {
    const options = {
      headers: { authorization: token },
    };

    try {
      axios.post(process.env.REACT_APP_API_URL + "app/slider/delete/" + id, {}, options).then((response) => {
        if (response.data.status == "success") {
          toast(response.data.message);
          fetchData(1);
          setShowSwal(false);
        } else {
          toast.error("Something went wrong");
        }
      });
    } catch (err) {
      toast.error(err.message);
    }
  }

  // On File Select Load It To Memory
  function fileHandler(e) {
    const file = e.currentTarget.files[0];
    setUploadData(file);
  }

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={"Sliders"}>
              {userData.role && userData.role.access.sliders.add && (
                <li className="mc-breadcrumb-item">
                  <Anchor
                    icon={"add"}
                    text={"Add"}
                    href={"#"}
                    className="mc-btn primary"
                    onClick={() => {
                      setShow(true);
                      setTitle("");
                      setDesc("");
                      setMode("add");
                      setUploadData(false);
                    }}
                  />
                </li>
              )}
            </Breadcrumb>
          </CardLayout>
        </Col>

        <Col xl={12}>
          <CardLayout>
            <Row>
              <Col xl={12}>
                <Box className="mc-table-responsive">
                  <Table className="mc-table">
                    <Thead className="mc-table-head primary">
                      <Tr>
                        <Th>Title</Th>
                        <Th>Image</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody className="mc-table-body even">
                      {data?.length > 0 &&
                        data.map((item, key) => (
                          <>
                            <Tr key={key}>
                              <Td>
                                <strong>{item.title}</strong>
                              </Td>
                              <Td>
                                <img src={process.env.REACT_APP_IMG_URL + "sliders/cropped/" + item.image} className="img-fluid" style={{ height: "50px" }} />
                              </Td>
                              <Td>
                                <Box className="mc-table-action">
                                  {userData.role && userData.role.access.sliders.edit && (
                                    <Anchor
                                      href="#"
                                      title="Edit"
                                      className="material-icons edit"
                                      onClick={() => {
                                        setMode("edit");
                                        setTitle(item.title);
                                        setDesc(item.desc);
                                        setId(item._id);
                                        setLink(item.link);
                                        setShow(true);
                                      }}
                                    >
                                      <Icon>edit</Icon>
                                    </Anchor>
                                  )}
                                  {userData.role && userData.role.access.sliders.delete && (
                                    <Button
                                      title="Delete"
                                      className="material-icons delete"
                                      onClick={() => {
                                        setId(item._id);
                                        setShowSwal(true);
                                      }}
                                    >
                                      <Icon>delete</Icon>
                                    </Button>
                                  )}
                                </Box>
                              </Td>
                            </Tr>
                          </>
                        ))}
                    </Tbody>
                  </Table>
                </Box>
              </Col>
            </Row>
          </CardLayout>
        </Col>
      </Row>

      <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{mode == "add" ? "Add" : "Edit"} Destination</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col xl={12}>
              <LabelField label="Name" type="text" fieldSize="w-100 h-md" value={title} onChange={(e) => setTitle(e.target.value)} />
            </Col>
            <Col xl={12}>
              <div className="mc-label-field-group label-col">
                <label className="mc-label-field-title"> Description (Optional)</label>
                <textarea type="text" placeholder="Type here..." className="mc-label-field-textarea w-100 h-text-md" value={desc} onChange={(e) => setDesc(e.target.value)}></textarea>
              </div>
            </Col>

            <Col xl={12}>
              <LabelField label="(Optional)" type="text" fieldSize="w-100 h-md" value={link} onChange={(e) => setLink(e.target.value)} />
            </Col>

            <Col xl={12}>
              <LabelField label="Image (1500x900)" type="file" fieldSize="w-100 h-md" accept="image/*" onChange={(e) => fileHandler(e)} />
            </Col>

            <Col xl={12}>
              <Button className="mc-btn primary w-100" icon={!Saving && "verified"} type="button" disable={!Saving} onClick={() => handleSave()}>
                {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Submit</>}
              </Button>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>

      {showSwal && (
        <SweetAlert warning showCancel confirmBtnText="Yes, delete it!" confirmBtnBsStyle="danger" cancelBtnBsStyle="primary" title="Are you sure?" onConfirm={handleDelete} onCancel={() => setShowSwal(false)}>
          This listing will be permanently deleted from your account.
        </SweetAlert>
      )}
    </PageLayout>
  );
}
