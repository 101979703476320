import React, { useContext } from "react";
import { DrawerContext } from "../context/Drawer";
import Section from "../components/elements/Section";
import { Logout } from "../components/sidebar";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
export default function Sidebar() {
  const { userData, token, refreshToken } = useSelector((state) => state.counter);

  const { drawer } = useContext(DrawerContext);
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Section as="aside" className={`mc-sidebar thin-scrolling ${drawer ? "active" : ""}`}>
      <ul class="mc-sidebar-menu-list">
        <li className={location.pathname == "/crm" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
          <a class="mc-sidebar-menu-btn" href="/crm" onClick={() => navigate("/crm")}>
            <i class="material-icons">dashboard</i>
            <span>Dashboard</span>
          </a>
        </li>

        {userData.role && userData.role.access.analytics.view && (
          <li className={location.pathname == "/analytics" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
            <a class="mc-sidebar-menu-btn" href="/analytics" onClick={() => navigate("/crm")}>
              <i class="material-icons">analytics</i>
              <span>Analytics</span>
            </a>
          </li>
        )}

        {userData.role && userData.role.access.log.view && (
          <li className={location.pathname == "/logs" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
            <a class="mc-sidebar-menu-btn" href="/logs" onClick={() => navigate("/crm")}>
              <i class="material-icons">hub</i>
              <span>Logs</span>
            </a>
          </li>
        )}

        {userData.role && userData.role.access.destinations.view && (
          <li className={location.pathname == "/destinations" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
            <a class="mc-sidebar-menu-btn" href="/destinations" onClick={() => navigate("/destinations")}>
              <i class="material-icons">pin_drop</i>
              <span>Destinations </span>
            </a>
          </li>
        )}

        {userData.role && userData.role.access.experiences.view && (
          <li className={location.pathname == "/experiences" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
            <a class="mc-sidebar-menu-btn" href="/experiences" onClick={() => navigate("/experiences")}>
              <i class="material-icons">favorite</i>
              <span>Experience</span>
            </a>
          </li>
        )}

        {userData.role && userData.role.access.activity.view && (
          <li className={location.pathname == "/activity" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
            <a class="mc-sidebar-menu-btn" href="/activity" onClick={() => navigate("/activity")}>
              <i class="material-icons">directions_run</i>
              <span>Activity Level</span>
            </a>
          </li>
        )}

        {userData.role && userData.role.access.products.view && (
          <li className={location.pathname == "/products" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
            <a class="mc-sidebar-menu-btn" href="/products" onClick={() => navigate("/products")}>
              <i class="material-icons">keyboard_command_key</i>
              <span>Products</span>
            </a>
          </li>
        )}

        {userData.role && userData.role.access.subDomains.view && (
          <li className={location.pathname == "/subdomains" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
            <a class="mc-sidebar-menu-btn" href="/subdomains" onClick={() => navigate("/subdomains")}>
              <i class="material-icons">language</i>
              <span>Sub Domains</span>
            </a>
          </li>
        )}

        {userData.role && userData.role.access.subDomains.view && (
          <li className={location.pathname == "/marketplace" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
            <a class="mc-sidebar-menu-btn" href="/marketplace" onClick={() => navigate("/marketplace")}>
              <i class="material-icons">assistant_navigation</i>
              <span>Marketplace</span>
            </a>
          </li>
        )}

        {userData.role && userData.role.access.sliders.view && (
          <li className={location.pathname == "/sliders" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
            <a class="mc-sidebar-menu-btn" href="/sliders" onClick={() => navigate("/sliders")}>
              <i class="material-icons">image</i>
              <span>Sliders</span>
            </a>
          </li>
        )}

        {userData.role && userData.role.access.partnerUs.view && (
          <li className={location.pathname == "/partner-with-us" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
            <a class="mc-sidebar-menu-btn" href="/partner-with-us" onClick={() => navigate("/partner-with-us")}>
              <i class="material-icons">handshake</i>
              <span>Partner with us</span>
            </a>
          </li>
        )}

        {userData.role && userData.role.access.blogs.view && (
          <li className={location.pathname == "/blogs" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
            <a class="mc-sidebar-menu-btn" href="/blogs" onClick={() => navigate("/blogs")}>
              <i class="material-icons">description</i>
              <span>Blogs</span>
            </a>
          </li>
        )}

        {userData.role && userData.role.access.travelFAQ.view && (
          <li className={location.pathname == "/faqs" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
            <a class="mc-sidebar-menu-btn" href="/faqs" onClick={() => navigate("/faqs")}>
              <i class="material-icons">help</i>
              <span>Travel FAQ</span>
            </a>
          </li>
        )}

        {userData.role && userData.role.access.otherFAQ.view && (
          <li className={location.pathname == "/other-faqs" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
            <a class="mc-sidebar-menu-btn" href="/other-faqs" onClick={() => navigate("/other-faqs")}>
              <i class="material-icons">quiz</i>
              <span>Ambassador FAQ</span>
            </a>
          </li>
        )}

        {userData.role && userData.role.access.testimonials && userData.role.access.testimonials.view && (
          <li className={location.pathname == "/testimonials" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
            <a class="mc-sidebar-menu-btn" href="/testimonials" onClick={() => navigate("/testimonials")}>
              <i class="material-icons">star</i>
              <span>Testimonials</span>
            </a>
          </li>
        )}

        {userData.role && userData.role.access.roles.view && (
          <li className={location.pathname == "/roles" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
            <a class="mc-sidebar-menu-btn" href="/roles" onClick={() => navigate("/roles")}>
              <i class="material-icons">fingerprint</i>
              <span>Roles</span>
            </a>
          </li>
        )}

        {userData.role && userData.role.access.users.view && (
          <li className={location.pathname == "/users" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
            <a class="mc-sidebar-menu-btn" href="/users" onClick={() => navigate("/users")}>
              <i class="material-icons">group</i>
              <span>Users</span>
            </a>
          </li>
        )}

        {userData.role && userData.role.access.settings.view && (
          <li className={location.pathname == "/settings" ? "mc-sidebar-menu-item active" : "mc-sidebar-menu-item"}>
            <a class="mc-sidebar-menu-btn" href="/settings" onClick={() => navigate("/settings")}>
              <i class="material-icons">settings</i>
              <span>Settings</span>
            </a>
          </li>
        )}
      </ul>
      <Logout />
    </Section>
  );
}
