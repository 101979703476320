import React from "react";
import { Box, Anchor } from "../elements";
import { setUserData, setToken, setRefreshToken, setLoginStatus } from "../../actions";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
export default function Logout() {
  const navigate = useNavigate();
  async function handleLogout() {
    setUserData([]);
    setLoginStatus(false);
    setToken("");
    setRefreshToken("");
    navigate("/");
    toast.success("Logged out");
  }

  return (
    <Box className="mc-sidebar-logout text-center mt-3">
      <Anchor href={"/"} text={"Logout"} className="mc-btn primary sm" onClick={() => handleLogout()} />
    </Box>
  );
}
