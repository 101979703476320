import React from "react";
import { Row, Col, Tab, Tabs } from "react-bootstrap";
import { LegendField, IconField } from "../../components/fields";
import { Button, Image } from "../../components/elements";
import { CardLayout, TabCard } from "../../components/cards";
import PageLayout from "../../layouts/PageLayout";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import axios from "axios";
import qs from "qs";
import toast from "react-hot-toast";
import Spinner from "react-bootstrap/Spinner";
import { setUserData } from "../../actions";
import { connect } from "react-redux";
const mapDispatchToProps = {
    setUserData,
};

const mapStateToProps = (state) => ({
    counter: state.counter.counter,
});

const Profile = ({ setUserData }) => {
    const { userData, token, refreshToken } = useSelector((state) => state.counter);

    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPasword, setConfirmPasword] = useState("");

    const [Saving, setSaving] = useState(false);

    useEffect(() => {
        setFname(userData.fname);
        setLname(userData.lname);
    }, []);

    async function handleSave() {
        if (!fname && fname == "") {
            toast.error("Enter first name");
            return;
        }

        if (!lname && lname == "") {
            toast.error("Enter first name");
            return;
        }

        setSaving(true);
        const options = {
            headers: { authorization: token },
            "Content-Type": "application/x-www-form-urlencoded",
        };

        var data = {};
        data["fname"] = fname;
        data["lname"] = lname;
        data["id"] = userData._id;
        const Response = await axios.post(process.env.REACT_APP_API_URL + "app/update-profile", qs.stringify(data), options);
        if (Response.data.status == "success") {
            setUserData(Response.data.data);
            setSaving(false);
            toast.success(Response.data.message);
        } else {
            setSaving(false);
            toast.error(Response.data.message);
        }
    }

    async function handlePasswordSave() {
        if (!currentPassword && currentPassword == "") {
            toast.error("Enter current password");
            return;
        }

        if (!newPassword && newPassword == "") {
            toast.error("Enter new password");
            return;
        }

        if (!confirmPasword && confirmPasword == "") {
            toast.error("Enter confirm password");
            return;
        }

        if (confirmPasword != newPassword) {
            toast.error("New pasword and confirm pasword must match");
            return;
        }

        setSaving(true);
        const options = {
            headers: { authorization: token },
            "Content-Type": "application/x-www-form-urlencoded",
        };

        var data = {};
        data["currentPassword"] = currentPassword;
        data["newPassword"] = newPassword;
        data["confirmPasword"] = confirmPasword;
        data["id"] = userData._id;
        const Response = await axios.post(process.env.REACT_APP_API_URL + "app/update-password", qs.stringify(data), options);
        if (Response.data.status == "success") {
            setSaving(false);
            setCurrentPassword("");
            setNewPassword("");
            setConfirmPasword("");

            toast.success(Response.data.message);
        } else {
            setSaving(false);
            toast.error(Response.data.message);
        }
    }

    return (
        <PageLayout>
            <Row>
                <Col xl={12}>
                    <CardLayout>
                        <Tabs defaultActiveKey="profile" id="mc" className="mc-tabs">
                            <Tab eventKey="profile" title="edit Profile" className="mc-tabpane profile">
                                <TabCard title="public information">
                                    <Row>
                                        <Col xl={8}>
                                            <Row>
                                                <Col xl={6}>
                                                    <LegendField title={"First name"} value={fname} onChange={(e) => setFname(e.target.value)} />
                                                </Col>
                                                <Col xl={6}>
                                                    <LegendField title={"Last name"} value={lname} onChange={(e) => setLname(e.target.value)} />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </TabCard>

                                <Button className="mc-btn primary btn-lg" icon="verified" disable={!Saving} onClick={() => handleSave()}>
                                    {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Save Changes</>}
                                </Button>
                            </Tab>
                            <Tab eventKey="password" title="Change Password" className="mc-tabpane password">
                                <TabCard title="generate password">
                                    <Row>
                                        <Col xs={12} md={12}>
                                            <IconField icon="lock" type="password" placeholder="current password" classes="w-100 h-lg" passwordVisible value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <IconField icon="add_moderator" type="password" placeholder="new password" classes="w-100 h-lg" passwordVisible value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <IconField icon="verified_user" type="password" placeholder="confirm password" classes="w-100 h-lg" passwordVisible value={confirmPasword} onChange={(e) => setConfirmPasword(e.target.value)} />
                                        </Col>
                                    </Row>
                                </TabCard>

                                <Button className="mc-btn primary btn-lg" icon="verified" disable={!Saving} onClick={() => handlePasswordSave()}>
                                    {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Save Changes</>}
                                </Button>
                            </Tab>
                        </Tabs>
                    </CardLayout>
                </Col>
            </Row>
        </PageLayout>
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
