import React from "react";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { CardLayout } from "./../../components/cards";
import { Table, Thead, Tbody, Th, Tr, Td } from "./../../components/elements/Table";
import { Anchor, Box, Text, Button, Icon } from "./../../components/elements";
import { Breadcrumb } from "./../../components";
import PageLayout from "../../layouts/PageLayout";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import Pagination from "react-js-pagination";
import qs from "qs";
import { FiThumbsUp, FiThumbsDown } from "react-icons/fi";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { FaExternalLinkSquareAlt } from "react-icons/fa";
import setLog from "../../lib";

export default function ProductList() {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose2 = () => setShow2(false);
  const { userData, token, refreshToken } = useSelector((state) => state.counter);
  const [showSwal, setShowSwal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerpage] = useState(20);
  const [totalRecod, setTotalRecod] = useState(0);
  const [data, setData] = useState({});
  const [data2, setData2] = useState([]);
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");

  const [masterDestinations, setMasterDestination] = useState("");
  const [subMasterDestinations, setSubMasterDestination] = useState("");
  const [destination, setDestination] = useState("");
  const [subDestination, setSubDestination] = useState("");

  const [masterExpereances, setMasterExpereance] = useState("");
  const [subMasterExpereances, setSubMasterExpereance] = useState("");
  const [expereance, setExpereance] = useState("");
  const [subExpereance, setSubExpereance] = useState("");
  const [keyword, seKeyword] = useState("");

  useEffect(() => {
    fetchDestination();
    fetchExpereance();
  }, []);

  async function fetchExpereance() {
    const options = {
      headers: { authorization: token },
    };

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/experiences/list", options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setMasterExpereance(Response.data.result);
      }
    } else {
      toast.error(Response.data.message);
    }
  }

  async function fetchDestination() {
    const options = {
      headers: { authorization: token },
    };

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/destination/list", options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setMasterDestination(Response.data.result);
      }
    } else {
      toast.error(Response.data.message);
    }
  }

  function getSubDestinations(id) {
    setDestination(id);
    if (masterDestinations) var data = masterDestinations.find((e) => e._id == id);
    if (data && data.childs) setSubMasterDestination(data.childs);
  }

  function getSubExpereance(id) {
    setExpereance(id);
    if (masterExpereances) var data = masterExpereances.find((e) => e._id == id);
    if (data && data.childs) setSubMasterExpereance(data.childs);
  }

  useEffect(() => {
    fetchData(1);
  }, []);

  async function fetchData(pageNumber) {
    const options = {
      headers: { authorization: token },
    };

    const params = {
      page: perPage * (pageNumber - 1),
      limit: perPage,
      destination: destination,
      subDestination: subDestination,
      expereance: expereance,
      subExpereance: subExpereance,
      keyword: keyword,
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/products/list?" + queryString, options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setTotalRecod(Response.data.totalCount);
        setData(Response.data.result);
      }
    } else {
      toast.error(Response.data.message);
    }
  }

  // Soft Delete Record
  async function handleDelete() {
    const options = {
      headers: { authorization: token },
    };

    try {
      axios.post(process.env.REACT_APP_API_URL + "app/products/delete/" + id, {}, options).then((response) => {
        if (response.data.status == "success") {
          setLog({
            adminUser: userData._id,
            module: "Master product",
            action: "Delete",
            note: title,
          });
          setTitle("");

          toast(response.data.message);
          fetchData(1);
          setActivePage(1);
          setShowSwal(false);
        } else {
          toast.error("Something went wrong");
        }
      });
    } catch (err) {
      toast.error(err.message);
    }
  }

  // On Pagination Change
  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    fetchData(pageNumber);
  }

  // Update The Status
  function UpdateStatus(id, featured, title) {
    let result = window.confirm("Are you sure?");
    if (result) {
      const options = {
        headers: { authorization: token },
      };

      axios.post(process.env.REACT_APP_API_URL + "app/products/update/" + id, qs.stringify({ featured: featured }), options).then((response) => {
        if (response.data.status == "success") {
          setLog({
            adminUser: userData._id,
            module: "Master product",
            action: "Featured status",
            note: (featured ? "True: " : "False: ") + title,
          });

          toast(response.data.message);
          handlePageChange(1);
        } else {
          toast(response.data.message);
        }
      });
    }
  }

  function UpdateVisible(id, displaySite, title) {
    let result = window.confirm("Are you sure?");
    if (result) {
      const options = {
        headers: { authorization: token },
      };

      axios.post(process.env.REACT_APP_API_URL + "app/products/update/" + id, qs.stringify({ displaySite: displaySite }), options).then((response) => {
        if (response.data.status == "success") {
          toast(response.data.message);
          handlePageChange(1);

          setLog({
            adminUser: userData._id,
            module: "Master product",
            action: "Visibility status",
            note: (displaySite ? "True: " : "False: ") + title,
          });
        } else {
          toast(response.data.message);
        }
      });
    }
  }

  function copyProduct(id, productTitle) {
    setShow(true);
    const options = {
      headers: { authorization: token },
    };
    axios.get(process.env.REACT_APP_API_URL + "app/products/copy?productId=" + id, options).then((response) => {
      if (response.data.status) {
        toast(response.data.message);
        setActivePage(1);
        fetchData(1);
        setShow(false);

        setLog({
          adminUser: userData._id,
          module: "Master product",
          action: "Copy",
          note: productTitle,
        });
      } else {
        setShow(false);
        toast(response.data.message);
      }
    });
  }

  function linkedDomain(id) {
    setShow(true);
    const options = {
      headers: { authorization: token },
    };
    axios.get(process.env.REACT_APP_API_URL + "app/products/domain?productId=" + id, options).then((response) => {
      if (response.data.success) {
        setShow(false);
        setShow2(true);
        setData2(response.data.result);
      } else {
        setShow(false);
        toast(response.data.message);
      }
    });
  }

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={"Products"}>
              {userData.role && userData.role.access.products.add && (
                <li className="mc-breadcrumb-item">
                  <Anchor icon={"add"} text={"Add"} href={"/manage-products"} className="mc-btn primary" />
                </li>
              )}
            </Breadcrumb>
          </CardLayout>
        </Col>

        <Col xl={12}>
          <CardLayout>
            <Row>
              <Col md={4}>
                <div class="form-group">
                  <label>Keyword</label>
                  <input type="text" placeholder="Title" className="form-control" onChange={(e) => seKeyword(e.target.value)} value={keyword} />
                </div>
              </Col>

              <Col md={4}>
                <div class="form-group">
                  <label>Destination</label>
                  <select class="form-control" onChange={(e) => getSubDestinations(e.target.value)} value={destination}>
                    <option value=""> Select </option>
                    {masterDestinations?.length > 0 && masterDestinations.map((item, key) => <option value={item._id}>{item.title}</option>)}
                  </select>
                </div>
              </Col>

              <Col md={4}>
                <div class="form-group">
                  <label>Sub Destination</label>
                  <select class="form-control" onChange={(e) => setSubDestination(e.target.value)} value={subDestination}>
                    <option value=""> Select </option>
                    {subMasterDestinations?.length > 0 && subMasterDestinations.map((item, key) => <option value={item._id}>{item.title}</option>)}
                  </select>
                </div>
              </Col>

              <Col md={4}>
                <div class="form-group">
                  <label>Experience</label>
                  <select class="form-control" onChange={(e) => getSubExpereance(e.target.value)} value={expereance}>
                    <option value=""> Select </option>
                    {masterExpereances?.length > 0 && masterExpereances.map((item, key) => <option value={item._id}>{item.title}</option>)}
                  </select>
                </div>
              </Col>

              <Col md={4}>
                <div class="form-group">
                  <label>Sub Experience</label>
                  <select class="form-control" onChange={(e) => setSubExpereance(e.target.value)} value={subExpereance}>
                    <option value=""> Select </option>
                    {subMasterExpereances?.length > 0 && subMasterExpereances.map((item, key) => <option value={item._id}>{item.title}</option>)}
                  </select>
                </div>
              </Col>

              <Col md={4}>
                <div class="form-group">
                  <Anchor
                    icon={"search"}
                    text={"Search"}
                    href={"#"}
                    className="mc-btn primary mt-4 w-100"
                    onClick={() => {
                      setActivePage(1);
                      fetchData(1);
                    }}
                  />
                </div>
              </Col>

              <Col xl={12}>
                <Box className="mc-table-responsive">
                  <Table className="mc-table">
                    <Thead className="mc-table-head primary">
                      <Tr>
                        <Th>Title</Th>
                        <Th>Location</Th>
                        <Th>Featured</Th>
                        <Th>Visible</Th>

                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody className="mc-table-body even">
                      {data?.length > 0 &&
                        data.map((item, key) => (
                          <>
                            <Tr key={key}>
                              <Td>
                                <strong>{item.title}</strong>
                              </Td>
                              <Td>
                                <strong>{item.parentDestination && item.parentDestination.title}</strong> - {item.subDestination && item.subDestination.title}
                              </Td>
                              <Td>
                                {userData.role && userData.role.access.products.edit && (
                                  <>
                                    {item.featured == 0 ? (
                                      <a href="#" onClick={() => UpdateStatus(item._id, 1, item.title)}>
                                        <FiThumbsDown size={25} title="No" className="text-danger" />
                                      </a>
                                    ) : (
                                      <a href="#" onClick={() => UpdateStatus(item._id, 0, item.title)}>
                                        <FiThumbsUp size={25} title=" Yes" className="text-success" />
                                      </a>
                                    )}
                                  </>
                                )}
                              </Td>
                              <Td>
                                {userData.role && userData.role.access.products.edit && (
                                  <>
                                    {item.displaySite == 0 ? (
                                      <a href="#" onClick={() => UpdateVisible(item._id, 1, item.title)}>
                                        <FiThumbsDown size={25} title="No" className="text-danger" />
                                      </a>
                                    ) : (
                                      <a href="#" onClick={() => UpdateVisible(item._id, 0, item.title)}>
                                        <FiThumbsUp size={25} title=" Yes" className="text-success" />
                                      </a>
                                    )}
                                  </>
                                )}
                              </Td>

                              <Td>
                                <Box className="mc-table-action">
                                  {userData.role && userData.role.access.products.edit && (
                                    <Anchor href={"#"} onClick={() => linkedDomain(item._id)} title="Linked domain" className="material-icons add text-dark">
                                      <Icon>dns</Icon>
                                    </Anchor>
                                  )}

                                  {userData.role && userData.role.access.products.edit && (
                                    <Anchor
                                      href={"#"}
                                      onClick={() => {
                                        copyProduct(item._id, item.title);
                                      }}
                                      title="Copy"
                                      className="material-icons add text-dark"
                                    >
                                      <Icon>content_copy</Icon>
                                    </Anchor>
                                  )}

                                  {userData.role && userData.role.access.products.edit && (
                                    <Anchor href={"/manage-products?id=" + item._id} title="Edit" className="material-icons edit">
                                      <Icon>edit</Icon>
                                    </Anchor>
                                  )}
                                  {userData.role && userData.role.access.products.delete && (
                                    <Button
                                      title="Delete"
                                      className="material-icons delete"
                                      onClick={() => {
                                        setId(item._id);
                                        setShowSwal(true);
                                        setTitle(item.title);
                                      }}
                                    >
                                      <Icon>delete</Icon>
                                    </Button>
                                  )}
                                </Box>
                              </Td>
                            </Tr>
                          </>
                        ))}
                    </Tbody>
                  </Table>
                </Box>{" "}
                <Box className="mc-paginate">
                  <Text className="mc-paginate-title">
                    Total <b>{totalRecod}</b> Results Found
                  </Text>
                  <nav aria-label="Page navigation example">
                    {totalRecod > perPage ? (
                      <Pagination
                        prevPageText="prev"
                        nextPageText="next"
                        firstPageText="first"
                        lastPageText="last"
                        activePage={activePage}
                        itemsCountPerPage={perPage}
                        totalItemsCount={totalRecod}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                        innerClass="pagination justify-content-center"
                      />
                    ) : (
                      ""
                    )}
                  </nav>
                </Box>
              </Col>
            </Row>
          </CardLayout>
        </Col>
      </Row>

      {showSwal && (
        <SweetAlert warning showCancel confirmBtnText="Yes, delete it!" confirmBtnBsStyle="danger" cancelBtnBsStyle="primary" title="Are you sure?" onConfirm={handleDelete} onCancel={() => setShowSwal(false)}>
          This listing will be permanently deleted from your account.
        </SweetAlert>
      )}
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Body>
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            &nbsp;&nbsp;&nbsp; <span className="h3">Please wait...</span>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>Product domain relation</Modal.Header>
        <Modal.Body style={{ width: 500 }}>
          <Box className="mc-table-responsive">
            <Table className="mc-table">
              <Thead className="mc-table-head primary">
                <Tr>
                  <Th>#</Th>
                  <Th>Domain</Th>
                </Tr>
              </Thead>
              <Tbody className="mc-table-body even">
                {data2?.length > 0 &&
                  data2.map((item, key) => (
                    <>
                      <Tr key={key}>
                        <Td>
                          <strong>{key + 1}</strong>
                        </Td>
                        <Td>
                          <strong>
                            {item.subdomain && (
                              <>
                                <a href={"https://" + item.subdomain.retreatURL + ".iamfit4travel.com"} target="_blank">
                                  <FaExternalLinkSquareAlt /> {item.subdomain.retreatURL + ".iamfit4travel.com"}
                                </a>
                              </>
                            )}
                          </strong>
                        </Td>
                      </Tr>
                    </>
                  ))}
              </Tbody>
            </Table>
          </Box>
        </Modal.Body>
      </Modal>
    </PageLayout>
  );
}
