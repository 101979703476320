import React from "react";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { CardLayout } from "./../../components/cards";
import PageLayout from "../../layouts/PageLayout";
import { useSelector } from "react-redux";
import "suneditor/dist/css/suneditor.min.css";
import { Breadcrumb } from "./../../components";
import Marketplace from "../blocks/Marketplace";
import { useNavigate } from "react-router-dom";

export default function ProductList() {
  const navigate = useNavigate();
  const { userData, token, refreshToken } = useSelector((state) => state.counter);

  ////////////////////

  const [Saving, setSaving] = useState(false);

  ////////////////////

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={"Marketplace"}></Breadcrumb>
          </CardLayout>
        </Col>
        <Col xl={12}>
          <CardLayout>
            <Row>
              <Col xl={12}>
                <Marketplace />
              </Col>
            </Row>
          </CardLayout>
        </Col>
      </Row>
    </PageLayout>
  );
}
