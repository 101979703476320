import React from "react";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Table, Thead, Tbody, Th, Tr, Td } from "./../../components/elements/Table";
import { Anchor, Box, Text } from "./../../components/elements";

import axios from "axios";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import setLog from "../../lib";

export default function ProductList({ subdomainId, name }) {
  const navigate = useNavigate();
  const { userData, token, refreshToken } = useSelector((state) => state.counter);

  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerpage] = useState(20);
  const [totalRecod, setTotalRecod] = useState(0);
  const [data, setData] = useState({});
  const [retreatsproducts, setRetreatsproducts] = useState([]);

  const [masterDestinations, setMasterDestination] = useState("");
  const [subMasterDestinations, setSubMasterDestination] = useState("");
  const [destination, setDestination] = useState("");
  const [subDestination, setSubDestination] = useState("");

  const [masterExpereances, setMasterExpereance] = useState("");
  const [subMasterExpereances, setSubMasterExpereance] = useState("");
  const [expereance, setExpereance] = useState("");
  const [subExpereance, setSubExpereance] = useState("");
  const [keyword, seKeyword] = useState("");

  useEffect(() => {
    fetchDestination();
    fetchExpereance();
  }, []);

  async function fetchExpereance() {
    const options = {
      headers: { authorization: token },
    };

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/experiences/list", options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setMasterExpereance(Response.data.result);
      }
    } else {
      toast.error(Response.data.message);
    }
  }

  async function fetchDestination() {
    const options = {
      headers: { authorization: token },
    };

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/destination/list", options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setMasterDestination(Response.data.result);
      }
    } else {
      toast.error(Response.data.message);
    }
  }

  function getSubDestinations(id) {
    setDestination(id);
    if (masterDestinations) var data = masterDestinations.find((e) => e._id == id);
    if (data && data.childs) setSubMasterDestination(data.childs);
  }

  function getSubExpereance(id) {
    setExpereance(id);
    if (masterExpereances) var data = masterExpereances.find((e) => e._id == id);
    if (data && data.childs) setSubMasterExpereance(data.childs);
  }

  useEffect(() => {
    fetchData(1);
  }, []);

  async function fetchData(pageNumber) {
    const options = {
      headers: { authorization: token },
    };

    const params = {
      page: perPage * (pageNumber - 1),
      limit: perPage,
      destination: destination,
      subDestination: subDestination,
      expereance: expereance,
      subExpereance: subExpereance,
      keyword: keyword,
      subdomainId: subdomainId,
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/products/list?" + queryString, options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setTotalRecod(Response.data.totalCount);
        setData(Response.data.result);
        setRetreatsproducts(Response.data.retreatsproducts);
      }
    } else {
      toast.error(Response.data.message);
    }
  }

  // On Pagination Change
  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    fetchData(pageNumber);
  }

  async function assignProduct(productId, title) {
    const options = {
      headers: { authorization: token },
    };

    const params = {
      productId: productId,
      subdomainId: subdomainId,
    };
    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");
    await axios.get(process.env.REACT_APP_API_URL + "app/sub-domain/assign-product?" + queryString, options);

    setLog({
      adminUser: userData._id,
      module: "Subdomain Product: " + name,
      action: "Assign/Unassign",
      note: title,
    });
  }

  function isChecked(id) {
    var data = retreatsproducts.find(function (ele) {
      return ele.products == id;
    });
    if (data) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      <Row>
        <Col md={4}>
          <div class="form-group">
            <label>Keyword</label>
            <input type="text" placeholder="Title" className="form-control" onChange={(e) => seKeyword(e.target.value)} value={keyword} />
          </div>
        </Col>

        <Col md={4}>
          <div class="form-group">
            <label>Destination</label>
            <select class="form-control" onChange={(e) => getSubDestinations(e.target.value)} value={destination}>
              <option value=""> Select </option>
              {masterDestinations?.length > 0 && masterDestinations.map((item, key) => <option value={item._id}>{item.title}</option>)}
            </select>
          </div>
        </Col>

        <Col md={4}>
          <div class="form-group">
            <label>Sub Destination</label>
            <select class="form-control" onChange={(e) => setSubDestination(e.target.value)} value={subDestination}>
              <option value=""> Select </option>
              {subMasterDestinations?.length > 0 && subMasterDestinations.map((item, key) => <option value={item._id}>{item.title}</option>)}
            </select>
          </div>
        </Col>

        <Col md={4}>
          <div class="form-group">
            <label>Experience</label>
            <select class="form-control" onChange={(e) => getSubExpereance(e.target.value)} value={expereance}>
              <option value=""> Select </option>
              {masterExpereances?.length > 0 && masterExpereances.map((item, key) => <option value={item._id}>{item.title}</option>)}
            </select>
          </div>
        </Col>

        <Col md={4}>
          <div class="form-group">
            <label>Sub Experience</label>
            <select class="form-control" onChange={(e) => setSubExpereance(e.target.value)} value={subExpereance}>
              <option value=""> Select </option>
              {subMasterExpereances?.length > 0 && subMasterExpereances.map((item, key) => <option value={item._id}>{item.title}</option>)}
            </select>
          </div>
        </Col>

        <Col md={4}>
          <div class="form-group">
            <Anchor
              icon={"search"}
              text={"Search"}
              href={"#"}
              className="mc-btn primary mt-4 w-100"
              onClick={() => {
                setActivePage(1);
                fetchData(1);
              }}
            />
          </div>
        </Col>

        <Col xl={12}>
          <Box className="mc-table-responsive">
            <Table className="mc-table">
              <Thead className="mc-table-head primary">
                <Tr>
                  <Th>Title</Th>
                  <Th>Location</Th>
                  <Th>Assign</Th>
                </Tr>
              </Thead>
              <Tbody className="mc-table-body even">
                {data?.length > 0 &&
                  data.map((item, key) => (
                    <>
                      <Tr key={key}>
                        <Td>
                          <strong>{item.title}</strong>
                        </Td>
                        <Td>
                          <strong>{item.parentDestination && item.parentDestination.title}</strong> - {item.subDestination && item.subDestination.title}
                        </Td>
                        <Td>
                          <input
                            class="form-check-input"
                            type="checkbox"
                            defaultChecked={isChecked(item._id)}
                            onChange={() => {
                              assignProduct(item._id, item.title);
                            }}
                          />
                        </Td>
                      </Tr>
                    </>
                  ))}
              </Tbody>
            </Table>
          </Box>
          <Box className="mc-paginate">
            <Text className="mc-paginate-title">
              Total <b>{totalRecod}</b> Results Found
            </Text>
            <nav aria-label="Page navigation example">
              {totalRecod > perPage ? (
                <Pagination
                  prevPageText="prev"
                  nextPageText="next"
                  firstPageText="first"
                  lastPageText="last"
                  activePage={activePage}
                  itemsCountPerPage={perPage}
                  totalItemsCount={totalRecod}
                  onChange={handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                  innerClass="pagination justify-content-center"
                />
              ) : (
                ""
              )}
            </nav>
          </Box>
        </Col>
      </Row>
    </>
  );
}
