import React from "react";
import { Anchor, Box, Icon } from "../elements";
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";
import { Text } from "./../../components/elements";

export default function PagesTable({ thead, tbody, totalRecod }) {
  return (
    <Box className="mc-table-responsive thin-scrolling">
      <Table className="mc-table">
        <Thead className="mc-table-head primary">
          <Tr>
            {thead.map((item, index) => (
              <Th key={index}>{item}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody className="mc-table-body">
          {tbody.map((item, index) => (
            <Tr key={index}>
              <Td>
                <Box className="mc-table-icon">
                  <Icon type="open_in_new" style={{ fontSize: "18px" }} />
                  <Anchor href={item.path} target={"_blank"}>
                    {item.path}
                  </Anchor>
                </Box>
              </Td>
              <Td>{item.visitor}</Td>
              <Td>{item.bounce}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <br />
      <Text className="mc-paginate-title">
        Total <b>{totalRecod}</b> Results Found
      </Text>
    </Box>
  );
}
